import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Guidelines from 'pages/Guidelines';

const GuidelinesLayout = () => (
  <Switch>
    <Route component={Guidelines} />
  </Switch>
);

export default GuidelinesLayout;
